<template lang="pug">
    section#galeria
        .wrapper
            h3(v-if="contents.title") {{ contents.title.pt }}
            .controls(v-if="images.length > 4")
                button.left(@click="moveGaleria(posicao - 1)" :disabled="posicao == 0")
                    SvgIcon(data="@svgs/seta-ippuc.svg")
                button.right(@click="moveGaleria(posicao + 1)" :disabled="disabledButton")
                    SvgIcon(data="@svgs/seta-ippuc.svg")
            .galeria
                ul.trilho(:class="[tipoGaleria]", ref="trilho")
                    li.imagem(v-for="image, index in images", ref="imagem", @click="showGaleria(contents.content, index)")
                        //- h4 {{ getCaption(image) }}
                        Imagem(:contents="image")
</template>

<script>
import { components, props } from '@/mixins/components'

export default {
  name: 'section-galeria-grid',
  props,
  components,
  computed: {
    tipoGaleria () {
      return this.contents.galleryType || 'grid8'
    },
    images () {
      return this.contents.content || []
    },
    quantidadeColunas () {
      if (this.tipoGaleria == 'grid8')
			    // if(window.innerWidth < 429)
      //     return Math.ceil(this.images.length/2)
      // else
      { return Math.ceil(this.images.length / 4) }
      return this.images.length
    },
    quadroSize () {
      if (this.$refs.imagem) { return parseFloat(window.getComputedStyle(this.$refs.imagem[0]).width) }
      return 0
    }
  },
  mounted () {
    if (this.$refs && this.$refs.trilho) { this.$refs.trilho.style.width = (this.quadroSize * this.quantidadeColunas) + 'px' }
  },
  methods: {
    moveGaleria (index) {
      this.posicao = index
      this.$refs.trilho.style.transform = `translateX(-${this.quadroSize * this.posicao}px)`
      // if(this.posicao == this.quantidadeColunas - 4 && window.innerWidth < 429)
      //     this.disabledButton = true
      if (this.tipoGaleria == 'grid4') {
        if (window.innerWidth >= 1025 && this.posicao == this.quantidadeColunas - 4) { this.disabledButton = true } else if (window.innerWidth <= 1024 && window.innerWidth > 428 && this.posicao == this.quantidadeColunas - 2) { this.disabledButton = true } else if (window.innerWidth <= 428 && this.posicao == this.quantidadeColunas - 1) { this.disabledButton = true } else { this.disabledButton = false }
      } else if (this.tipoGaleria == 'grid8') {
        if (window.innerWidth >= 1025 && this.posicao == this.quantidadeColunas) { this.disabledButton = true } else if (window.innerWidth <= 1024 && this.posicao == this.quantidadeColunas - 2) { this.disabledButton = true } else { this.disabledButton = false }
      }
    },
    getCaption (image) {
      return image.desktop.caption || ''
    },
    showGaleria (contents, index) {
      this.$store.commit('setAsideGaleria', {
        index,
        contents
      })
    }
  },
  data () {
    return {
      posicao: 0,
      disabledButton: false
    }
  }
}
</script>

<style lang="stylus" scoped src="./GaleriaGrid.styl"></style>
